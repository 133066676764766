import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperSplitExercise'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const TextStepper = makeShortcode("TextStepper");
const ImageGrid = makeShortcode("ImageGrid");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encourage-daily-exercise"
    }}>{`Encourage daily exercise`}</h1>
    <h3 {...{
      "id": "how-much-physical-exercise-should-my-teenager-be-doing"
    }}>{`How much physical exercise should my teenager be doing?`}</h3>
    <p>{`It is recommended that teenagers do at least 60 minutes of moderate to vigorous physical activity every day.`}</p>
    <Paper mdxType="Paper">
  <p>
    <b>
      Research<sup>4</sup> shows that:
    </b>
  </p>
  <p>Higher levels of physical activity among teenagers are associated with fewer depressive symptoms.</p>
    </Paper>
    <h3 {...{
      "id": "do-the-60-minutes-have-to-be-done-all-at-once"
    }}>{`Do the 60 minutes have to be done all at once?`}</h3>
    <p>{`No. In fact, it is often easier to reach the goal of 60 minutes if your teenager does different activities across the day. Here are some examples:`}</p>
    <TextStepper id="textStepperSplitExercise" sequenceSpeed={1200} fadeSpeed={1200} list={[{
      text: '20 mins walking to and from the bus stop, 30 mins in P.E. class and 10 mins walking the dog.'
    }, {
      text: '30 mins riding a bike to & from school and 30 mins of playing football with friends.'
    }]} mdxType="TextStepper" />
    <ImageGrid list={[{
      src: '/images/m7/33.svg',
      alt: 'walking the dog'
    }, {
      src: '/images/shared/Oriental_Fa_Son_ball.svg',
      alt: 'father and son playing ball'
    }]} mdxType="ImageGrid" />
    <sup>
  4. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      